/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import * as ReactDOM from "react-dom";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Provider } from "react-redux";
import * as serviceWorker from "serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import CONFIG from "config";

import "sanitize.css/sanitize.css";
import "assets/styles/custom.scss";

// Import root app
import { App } from "app";

import { configureAppStore } from "store/configureStore";

JavascriptTimeAgo.addLocale(en);

Sentry.init({
  environment: CONFIG.env,
  dsn: CONFIG.sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1
});

ReactGA.initialize(CONFIG.googleAnalyticsTrackingCode, {
  debug: CONFIG.env === "development",
  testMode: CONFIG.env === "test"
});

if (CONFIG.env === "production") {
  hotjar.initialize(CONFIG.hotJarId, CONFIG.hotJarSnippetVersion);
}

const store = configureAppStore();
const MOUNT_NODE = document.querySelector("#root") as HTMLElement;

interface Props {
  Component: typeof App;
}

const ConnectedApp = ({ Component }: Props): JSX.Element => (
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <Sentry.ErrorBoundary>
          <Component />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

const render = (Component: typeof App): void => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./app"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // eslint-disable-next-line global-require
    const { MainApp } = require("./app");
    render(MainApp);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

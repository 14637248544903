/**
 *
 * Asynchronously loads the component for UnitPage
 *
 */

import { lazyLoad } from "utils/loadable";

export const UnitPage = lazyLoad(
  () => import("./index"),
  module => module.UnitPage
);

/* --- STATE --- */
import { IBasicPropertyInformation, IListingFull } from "../ICommonTypes";

export interface IProperty extends Partial<IBasicPropertyInformation> {
  id: number;
  name: string;
  lat: string;
  lng: string;
  active_beds: number[];
  active_beds_range: string;
  active_rent_range: string;
  min_beds: string | number;
  max_beds: string | number;
  active_min_rent: number;
  active_max_rent: number;
  min_rent: number;
  max_rent: number;
  rent_range: string;
  beds_range: string;
  property_type_id: number;
  description: string;
  number_units: number;
  twilio_number: string;
  application_url: string;
}

export interface PropertyState {
  isLoaded: boolean;
  data: {
    property: Partial<IProperty>;
    listings: Partial<IListingFull[]>;
  };
}

export const initialState: PropertyState = {
  isLoaded: false,
  data: {
    property: {
      id: -1,
      landlord_id: -1,
      page_title: "",
      meta_description: "",
      address: "",
      updated: "0",
      name: "",
      display_name: "",
      city: "",
      state: "",
      zip_code: -1,
      lat: "0",
      lng: "0",
      breadcrumbs: [],
      active_beds: [],
      active_beds_range: "",
      active_rent_range: "",
      min_beds: "",
      max_beds: "",
      active_min_rent: 0,
      active_max_rent: 0,
      min_rent: 0,
      max_rent: 0,
      rent_range: "",
      beds_range: "",
      property_type_id: -1,
      lead_email_address: "",
      phone_number: "",
      description: "",
      number_units: -1,
      twilio_number: "",
      application_url: ""
    },
    listings: []
  }
};

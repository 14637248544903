/**
 * Create the store with dynamic reducers
 */

import { combineReducers, configureStore, EnhancedStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import CONFIG from "config";
import { reducer as landlordReducer } from "store/landlord/landlordSlice";
import { reducer as propertyReducer } from "store/property/propertySlice";
import { reducer as listingReducer } from "store/listing/listingSlice";
import { reducer as userReducer } from "store/user/userSlice";

export function configureAppStore(): EnhancedStore {
  return configureStore({
    reducer: combineReducers({
      landlord: landlordReducer,
      property: propertyReducer,
      listing: listingReducer,
      user: userReducer
    }),
    middleware: [...getDefaultMiddleware()],
    devTools: CONFIG.env !== "production"
  });
}

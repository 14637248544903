import { createGlobalStyle } from "styled-components";

import { mediaBreakpointDownSm, mediaBreakpointUpSm } from "styled-bootstrap-responsive-breakpoints";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: "Avenir Next", "Open Sans", "calibri", "Helvetica", Arial, Sans-Serif;
    font-weight: 500;
    color: #3C4858;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    background-color: #F9FAFC;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .active-nav {
    color: var(--primary) !important;
    text-decoration: none;

    #gallery-icon, #bed-type-icon, #faq-icon,  #location-icon, #details-icon, #inspection-icon {
      fill: var(--primary) !important;
    }
  }

  .disabled-nav {
    color: var(--gray) !important;
    opacity: .8;
    cursor: not-allowed;

    #gallery-icon, #bed-type-icon, #faq-icon,  #location-icon, #details-icon {
      fill: var(--gray) !important;
    }
  }

  .application-button svg {
    width: 16px;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .media-tile-interactive {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .final-media {
    filter: brightness(0.5);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    border: 1px solid #d3dce6;
    pointer-events: none;
  }

  .grid-tile--featured .breadcrumb {
    justify-content: center;
  }

  .text-green {
    color: var(--green);
  }

  .carousel-control-prev, .carousel-control-next {
    color: black;

    :hover {
      color: black;
    }

    svg {
      font-size: 2em;
    }
  }

  .cta-button {
    border-radius: 25px;
    height: 50px;
    font-weight: bold;
    background: linear-gradient(135deg, rgba(184, 33, 37, 1) 0%, rgba(151, 27, 30, 1) 50%);

    svg {
      padding-bottom: 2px;
    }
  }

  .media-carousel {
     width: 100%;

    .carousel-caption {
      color: black;
    }

    .carousel-control-prev, .carousel-control-next {
      width: 50px;

      ${mediaBreakpointDownSm`
        display: none;
      `}

      .carousel-control-prev-icon, .carousel-control-next-icon {
        background: none;
        height: 100%;
        width: 50px;
      }

      svg {
        color: black;
      }
    }

    .carousel-item.active {
      display: flex;
    }

    .carousel-item {
      width: fit-content;
      height: calc(100vh - 230px);
      float: none;
      margin: 0 auto;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      touch-action: manipulation;
    }

    .interactive-media {
      max-height: calc(100% - 64px);
      max-width: calc(100% - 32px);
      width: 100vw;
      height: 100%;

      ${mediaBreakpointUpSm`
        max-width: calc(100% - 102px);
      `}
    }

    .carousel-inner {
      overflow: visible;
    }
  }

  .fullscreen-modal {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-header {
      border: 0;
    }

    .modal-body {
      overflow-y: unset;
    }

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .contact-address-header {
    color: #3C4858;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 15px;
  }
  .contact-address {
    color: #3C4858;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }
  .contact-header {
    color: #3C4858;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
  }
  .contact-property-name {
    color: #3C4858;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
  }
  .round-corners {
    border-radius: 19px;
  }
  .modal-header {
    border-bottom: none;
  }
 `;

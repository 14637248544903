import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { initialState } from "./LandlordState";
import { IDetail } from "../ICommonTypes";

const landlordSlice = createSlice({
  name: "landlord",
  initialState,
  reducers: {
    setLandlordLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setDetailsData(state, action: PayloadAction<Partial<IDetail[]>>) {
      state.data.landlord.details = action.payload || [];
    }
  }
});

export const { actions, reducer, name: sliceKey } = landlordSlice;

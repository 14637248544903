import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./UserState";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.data.name = action.payload;
    },
    setUserEmail(state, action: PayloadAction<string>) {
      state.data.email = action.payload;
    },
    setUserProposedTourDates(state, action: PayloadAction<string[]>) {
      state.data.proposedTourDates = action.payload;
    },
    setUserUnitsBedTypesFilter(state, action: PayloadAction<number[]>) {
      state.data.filters.unitsBedTypes = action.payload;
    }
  }
});

export const { actions, reducer } = userSlice;

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { GlobalStyle } from "assets/styles/global-styles";
import styled from "styled-components/macro";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import CONFIG from "config";
import { NotFoundPage } from "app/components/NotFoundPage/Loadable";
import { UnitsList } from "app/containers/UnitsListPage/Loadable";
import { UnitPage } from "app/containers/UnitPage/Loadable";
import { selectLandlordLogoUrl } from "store/landlord/landlordSelector";

export function App(): JSX.Element {
  const landlordLogoUrl = useSelector(selectLandlordLogoUrl);

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Rentify" defaultTitle="Rentify">
        <meta
          name="description"
          content="rentable is the easiest way to find your next apartment. We bring you the freshest apartment listings for rent every day to make your search easy."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeaderNavbar sticky="top" className="border-bottom bg-white">
        <Navbar.Brand href="https://rentable.co">
          <img
            alt="logo"
            src={`${CONFIG.cloudfrontBaseAssetsUrl}/${landlordLogoUrl}`}
            width="120"
            height="40"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
      </HeaderNavbar>
      <Container fluid="md">
        <Switch>
          <Route exact path="/:propertyId" component={UnitsList} />
          <Route exact path="/:propertyId/:unitId" component={UnitPage} />
          <Route component={NotFoundPage} />
        </Switch>
        <ToastContainer />
      </Container>
      <GlobalStyle />
    </BrowserRouter>
  );
}

const HeaderNavbar = styled(Navbar)`
  min-height: 60px;
  z-index: 1021;
`;

/**
 *
 * Asynchronously loads the component for UnitsList
 *
 */

import { lazyLoad } from "utils/loadable";

export const UnitsList = lazyLoad(
  () => import("./index"),
  module => module.UnitsList
);

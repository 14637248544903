import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import merge from "lodash/merge";
import { initialState, IProperty } from "./PropertyState";
import { IBasicPropertyInformation, IListingFull } from "../ICommonTypes";

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setPropertyLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setBasicPropertyInformationData(state, action: PayloadAction<Partial<IBasicPropertyInformation>>) {
      state.data.property = merge({}, state.data.property, action.payload);
    },
    setPropertyData(state, action: PayloadAction<Partial<IProperty>>) {
      state.data.property = merge({}, initialState.data.property, action.payload);
    },
    setListingData(state, action: PayloadAction<Partial<IListingFull[]>>) {
      state.data.listings = action.payload || [];
    },
    addListingData(state, action: PayloadAction<Partial<IListingFull[]>>) {
      state.data.listings = state.data.listings.concat(action.payload || []);
    }
  }
});

export const { actions, reducer, name: sliceKey } = propertySlice;

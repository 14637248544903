import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState, LandlordState } from "./LandlordState";

const getLandlordLogoUrl: (state: RootState) => string = (state: RootState) => {
  if (state && typeof state.landlord !== "undefined" && state.landlord.data.landlord !== "undefined") {
    const landlordLogoUrlDetail = state.landlord.data.landlord.details?.find(detail => detail?.name === "Logo URL");

    if (landlordLogoUrlDetail) {
      return landlordLogoUrlDetail.value;
    }
  }
  return "";
};

const getDomain = (state: RootState): LandlordState => state.landlord || initialState;

export const selectLandlord = createSelector([getDomain], landlordState => landlordState);

export const selectLandlordLogoUrl = createSelector([getLandlordLogoUrl], landlordLogoUrl => landlordLogoUrl);

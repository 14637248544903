/**
 *
 * Loading
 *
 */
import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";

export function Loading(): JSX.Element {
  return (
    <Container>
      <Row className="my-4">
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
}

import { PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import { createSlice } from "utils/@reduxjs/toolkit";
import { IAttr, IDetail, IInspection, IListing, IMedia } from "store/ICommonTypes";
import { initialState } from "./ListingState";

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setListingLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setBasicInformationData(state, action: PayloadAction<Partial<IListing>>) {
      state.data.listing = merge({}, initialState.data.listing, action.payload);
    },
    setDetailsData(state, action: PayloadAction<Partial<IDetail[]>>) {
      state.data.details = action.payload || [];
    },
    addDetailsData(state, action: PayloadAction<Partial<IDetail[]>>) {
      state.data.details = state.data.details.concat(action.payload || []);
    },
    setInspectionsData(state, action: PayloadAction<Partial<IInspection[]>>) {
      state.data.inspections = action.payload || [];
    },
    setAttrsData(state, action: PayloadAction<Partial<IAttr[]>>) {
      state.data.attrs = action.payload || [];
    },
    addAttrsData(state, action: PayloadAction<Partial<IAttr[]>>) {
      state.data.attrs = state.data.attrs.concat(action.payload || []);
    },
    setMediaData(state, action: PayloadAction<Partial<IMedia>>) {
      state.data.media = merge({}, initialState.data.media, action.payload);
    },
    setSimilarListingsMediaData(state, action: PayloadAction<Partial<IMedia>>) {
      state.data.similar_listings_media = merge({}, initialState.data.similar_listings_media, action.payload);
    }
  }
});

export const { actions, reducer, name: sliceKey } = listingSlice;

import { IAttr, IDetail, IInspection, IListing, IMedia } from "store/ICommonTypes";

export interface ListingState {
  isLoaded: boolean;
  data: {
    listing: Partial<IListing>;
    media: Partial<IMedia>;
    similar_listings_media: Partial<IMedia>;
    details: Partial<IDetail[]>;
    inspections: Partial<IInspection[]>;
    attrs: Partial<IAttr[]>;
  };
}

export const initialState: ListingState = {
  isLoaded: false,
  data: {
    listing: {
      id: -1,
      name: "",
      active: 1,
      bedrooms: -1,
      bathrooms: -1,
      tile_url: "",
      lease_length: "",
      updated: "0",
      min_rent: -1,
      max_rent: -1,
      rent_type: "",
      size: "",
      date_available: "0",
      description: "",
      unit_type: -1,
      room_type: -1
    },
    similar_listings_media: {
      photos: [],
      videos: [],
      you_tube_videos: [],
      matterports: []
    },
    attrs: [],
    details: [],
    inspections: [],
    media: {
      photos: [],
      you_tube_videos: [],
      videos: [],
      matterports: []
    }
  }
};

export interface IUserFilters {
  unitsBedTypes: number[];
}

export interface UserState {
  isLoaded: boolean;
  data: {
    name: string;
    email: string;
    proposedTourDates: string[];
    filters: IUserFilters;
  };
}

export const initialState: UserState = {
  isLoaded: false,
  data: {
    name: "",
    email: "",
    proposedTourDates: [],
    filters: {
      unitsBedTypes: []
    }
  }
};

const NODE_ENV = process.env.NODE_ENV || "development";

export default Object.freeze({
  env: NODE_ENV,
  cloudfrontBaseAssetsUrl: "https://public-photos.rentable.co",
  cloudfrontBaseImagesUrl: "https://images.rentable.co",
  railsBaseUrl: "https://www.rentable.co",
  railsApiToken: "xVJCvj6oxlrIiDibZI4SVg5Bk1NGlvPFB1gfrVTMCC4A",
  sentryDsn: "https://b9590856eea9454a8c63fc6d4d48fb79@o22891.ingest.sentry.io/5449177",
  googleAnalyticsTrackingCode: "UA-23139666-20",
  hotJarId: 2022111,
  hotJarSnippetVersion: 6
});

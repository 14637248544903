import { IDetail } from "store/ICommonTypes";

interface ILandlord {
  details: Partial<IDetail[]>;
}

export interface LandlordState {
  isLoaded: boolean;
  data: {
    landlord: Partial<ILandlord>;
  };
}

export const initialState: LandlordState = {
  isLoaded: false,
  data: {
    landlord: {
      details: []
    }
  }
};
